<template>
  <div class="cell" :style="{'background': item.color }">
    <div class="cell_content_full">
        <div class="cell_content_full_title">{{ item[currentLanguage].title }}</div>
        <div>{{ item[currentLanguage].subtitle }}</div>
        <router-link class="card_link" :to="$i18nRoute({ name: 'project', params: { id: item.id } })">{{ $t('home.activities.next') }}</router-link>
    </div>
    <div class="cell_bg"></div>
    <router-link class="cell_content" :to="$i18nRoute({ name: 'project', params: { id: item.id } })">
      <img class="cell_logo" :src="item.lightLogo" alt="logo">
      <!-- <div class="cell_title">{{ item[currentLanguage].title }}</div> -->
    </router-link>
  </div>
</template>

<script>
  import { Trans } from '@/shared/utils/TranslateMiddleware';

  export default {
    name: 'HexHomeComponent',
    props: {
      item: {
        type: Object,
        default: null
      }
    },
    computed: {
      currentLanguage () {
        return Trans.currentLanguage;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>