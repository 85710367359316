<template>
    <main class="main" v-bar>
        <div>
            <div class="section_top">
                <video autoplay muted loop playsinline preload="yes" class="bg_video" poster="/img/poster_mp.png">
                    <source src="/img/main_page_promo.mp4" type="video/mp4">
                </video>
                <h1 v-html="$t('home.header.slogan')">Єдине правило в житті, по якому потрібно жити<br>- завжди залишайся людиною!</h1>
                <div class="scroll_icon">
                    <i class="mdi mdi-chevron-down"></i>
                    <i class="mdi mdi-chevron-down"></i>
                </div>
            </div>
            <div class="home_section">
                <div class="section_greeting container lg_bg">
                    <h2>{{ $t('home.preview.title') }}</h2>
                    <div class="section_greeting_text">
                        <span v-html="$t('home.words')"></span>
                    </div>
                    <picture class="section_greeting_img">
                        <source srcset="/img/bn.webp" type="image/webp">
                        <img src="/img/bn.jpg" alt="bagrayev">
                    </picture>
                </div>
                <div class="container">
                    <div class="pre_title">{{ $t('home.activities.subtitle') }}</div>
                    <h2>{{ $t('home.activities.title') }}</h2>
                    <div class="honeycomb">
                        <hex-component :item="item" v-for="item in list" :key="item.id"></hex-component>
                    </div>
                </div>
                <div class="container lg_bg">
                    <router-link class="pre_title news_link" :to="{ name: 'media' }">{{ $t('home.read.subtitle') }}</router-link>
                    <h2>{{ $t('home.read.title') }}</h2>
                    <div class="cards_wrap">
                        <div class="card_wrap" v-for="item in news" :key="'news' + item.id">
                            <div class="card" v-if="item.type !== 'video'">
                                <div class="card_image" v-if="item.src">
                                    <img :src="item.src" alt="card_img">
                                </div>
                                <div class="card_content">
                                    <div class="card_title" v-if="item[currentLanguage].title">{{ item[currentLanguage].title | truncate(100) }}</div>
                                    <div class="card_text">{{ item[currentLanguage].text | truncate(250) }}</div>
                                    <a :href="item.ref" target="_blank" rel="noreferrer" class="card_link">{{ $t('home.activities.next') }}</a>
                                </div>
                            </div>
                            <a v-if="item.type === 'video'" class="card_video video_js" :href="item.ref">
                                <img :src="item.src" alt="card_img">
                                <div class="card_video_text">{{ item[currentLanguage].title | truncate(100) }}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="preloader_wrap active">
            <div class="preloader loading">
                <span class="slice"></span>
                <span class="slice"></span>
                <span class="slice"></span>
                <span class="slice"></span>
                <span class="slice"></span>
                <span class="slice"></span>
            </div>
        </div> -->
    </main>
</template>

<script>
import { TimelineMax } from 'gsap';
import { mapGetters, mapActions } from 'vuex';
import { Trans } from '@/shared/utils/TranslateMiddleware';
import truncate from '@/shared/filters/truncate'; // eslint-disable-line

import HexComponent from '../components/HexHome';

export default {
    name: 'HomePageComponent',
    components: {
        HexComponent
    },
    computed: {
        ...mapGetters('projects', {
            list: 'getListHome'
        }),
        ...mapGetters('news', {
            news: 'getLastNews'
        }),
        currentLanguage () {
            return Trans.currentLanguage;
        }
    },
    methods: {
        ...mapActions('news', {
            fetchListNews: 'fetchList'
        }),
        async honeycombResize() {
            if (!this.list.length) return;

            await this.$nextTick();

            let mainEl = $('.honeycomb');// eslint-disable-line
            let mainWidth = mainEl.width();
            let childrenWidth = mainEl.children().outerWidth(true);
            let childrenCount = Math.floor(mainWidth / childrenWidth);
            mainEl.children().removeClass('margin');
            let n = childrenCount * 2 - 1;
            if ((mainWidth / childrenWidth)%1 >= 0.5){
                n = childrenCount * 2;
            }
            let selector = ':nth-child(' + n + 'n + ' + (childrenCount + 1) + ')';

            mainEl.children(selector).addClass('margin');
        },
        starting() {
            window.addEventListener('load', () => {
                $('.preloader_wrap').removeClass('active'); // eslint-disable-line
            });

            this.honeycombResize();
            window.addEventListener('resize', this.honeycombResize(), false);

            $('.video_js').fancybox({ // eslint-disable-line
                openEffect: 'fade',
                closeEffect: 'fade',
                helpers: {
                    media : {}
                },
                thumbs: {
                    autoStart: true,
                    axis: 'x'
                }
            });

            var tl = new TimelineMax();
            tl
                .to(('.section_top'), 0.3, {opacity: 1}, '+=0.5')
                .to(('.section_top h1'), 0.3, {opacity: 1, y: 0})
                .to(('.home_section'), 0.3, {opacity: 1, y: 0});
        }
    },
    watch: {
        list() {
            this.starting();
            this.honeycombResize();
        }
    },
    mounted() {
        this.starting();
        this.fetchListNews();
        $(window).resize(() => {// eslint-disable-line
            this.honeycombResize();
        });

    }
}
</script>

<style lang="scss" scoped>
    .news_link {
        cursor: pointer;
    }
</style>